import CookieConsent from './cookieconsent.umd.js';

// Enable dark mode
document.documentElement.classList.add('cc--darkmode');

CookieConsent.run({
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom left",
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: "box",
            position: "right",
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            readOnly: true
        },
        functionality: {},
        analytics: {}
    },
    language: {
        default: "en",
        autoDetect: "browser",
        translations: {
            de: {
                consentModal: {
                    title: "Diese Webseite verwendet Cookies",
                    description: "Um Ihnen ein optimales Nutzererlebnis zu bieten, verwendet unsere Webseite Cookies. Durch die weitere Nutzung der Seite stimmen Sie der Verwendung von Cookies zu. Weitere Informationen finden Sie in unserer Datenschutzerklärung.",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Alle ablehnen",
                    showPreferencesBtn: "Einstellungen verwalten",
                    footer: "<a href=\"/privacy\">Privacy Policy</a>"
                },
                preferencesModal: {
                    title: "Präferenzen für die Zustimmung",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Alle ablehnen",
                    savePreferencesBtn: "Einstellungen speichern",
                    closeIconLabel: "Modal schließen",
                    serviceCounterLabel: "Dienstleistungen",
                    sections: [
                        {
                            "title": "Verwendung von Cookies",
                            "description": "Um Ihnen ein optimales Nutzererlebnis zu bieten, verwendet unsere Webseite Cookies. Weitere Informationen finden Sie in unserer Datenschutzerklärung."
                        },
                        {
                            "title": "Streng notwendige Cookies <span class=\"pm__badge\">Immer Aktiviert</span>",
                            "description": "Diese Cookies sind unerlässlich, damit Sie sich auf der Webseite bewegen und ihre Funktionen nutzen können. Ohne diese Cookies können bestimmte Dienste nicht bereitgestellt werden.",
                            "linkedCategory": "necessary"
                        },
                        {
                            "title": "Funktionalitäts Cookies",
                            "description": "Diese Cookies ermöglichen es der Webseite, erweiterte Funktionalität und Personalisierung anzubieten. Sie können von uns oder von Drittanbietern gesetzt werden, deren Dienste wir auf unseren Seiten verwenden.",
                            "linkedCategory": "functionality"
                        },
                        {
                            "title": "Analytische Cookies",
                            "description": "Diese Cookies sammeln Informationen darüber, wie Besucher unsere Webseite nutzen, zum Beispiel welche Seiten am häufigsten besucht werden und ob sie Fehlermeldungen von Webseiten erhalten.",
                            "linkedCategory": "analytics"
                        },                        
                        {
                            title: "Weitere Informationen",
                            description: "For any query in relation to my policy on cookies and your choices, please contact us."
                        }
                    ]
                }
            },
            en: {
                consentModal: {
                    title: "This Website Uses Cookies",
                    description: "To provide you with the best user experience, our website uses cookies. By continuing to browse the site, you agree to our use of cookies. For more information, please see our Privacy Policy.",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    showPreferencesBtn: "Manage preferences",
                    footer: "<a href=\"/privacy\">Privacy Policy</a>"
                },
                preferencesModal: {
                    title: "Consent Preferences Center",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    savePreferencesBtn: "Save preferences",
                    closeIconLabel: "Close modal",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            "title": "Use of Cookies",
                            "description": "To provide you with the best user experience, our website uses cookies. For more information, please see our Privacy Policy."
                        },
                        {
                            "title": "Strictly Necessary Cookies <span class=\"pm__badge\">Always Active</span>",
                            "description": "These cookies are essential for you to browse the website and use its features. Without these cookies, certain services cannot be provided.",
                            "linkedCategory": "necessary"
                        },
                        {
                            "title": "Functionality Cookies",
                            "description": "These cookies allow the website to offer enhanced functionality and personalization. They may be set by us or by third-party providers whose services we use on our pages.",
                            "linkedCategory": "functionality"
                        },
                        {
                            "title": "Analytical Cookies",
                            "description": "These cookies collect information about how visitors use our website, such as which pages are visited most often and if they receive error messages from web pages.",
                            "linkedCategory": "analytics"
                        },                        
                        {
                            title: "More information",
                            description: "For any query in relation to my policy on cookies and your choices, please contact us."
                        }
                    ]
                }
            }
        }
    }
});